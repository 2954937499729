<template>
  <v-container fluid class="px-lg-5">
    <app-modal
      v-show="isModalVisible"
      :modal="modal"
      @submit-modal="submitModal"
    />
    <app-header
      :title="title"
      :breadcrumbs="breadcrumbs"
      :actionButton="actionButton"
      @clicked="openCreateKudosModal"
      :showButton="true"
    />
    <v-card class="rounded-card" flat outlined>
      <v-data-table
        :headers="headers"
        :items="kudos.items"
        :options.sync="options"
        @update:options="updateOptions"
        :server-items-length="kudos.count"
        :loading="loading" flat
        :no-data-text="$t('messages.emptyState', { entity: $tc('models.kudo.entity') })"
        :no-results-text="$t('messages.emptyState', { entity: $tc('models.kudo.entity') })">
        <template v-if="this.isAdmin" v-slot:[`item.actions`]="{ item }">
          <v-btn icon small @click="deleteKudo(item)">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {
      isModalVisible: false,
      loading: false,
      modal: {
        show: false,
        id: '',
        behaviour: '',
        resetForm: false,
        title: '',
        submitButton: {
          label: this.$t('actions.save')
        },
        fields: {
          title: {
            type: 'text',
            key: 'title',
            value: '',
            label: this.$t('models.kudo.attributes.title'),
            rules: [{name: 'required'}],
            classes: 'col-12 py-0',
            serverErrors: []
          },
          description: {
            type: 'text',
            key: 'description',
            value: '',
            label: this.$t('models.kudo.attributes.description'),
            rules: [{name: 'required'}],
            classes: 'col-12 py-0',
            serverErrors: []
          },
          badge: {
            type: 'text',
            key: 'badge',
            value: '',
            label: this.$t('models.kudo.attributes.badge'),
            rules: [{name: 'required'}],
            classes: 'col-12 py-0',
            serverErrors: []
          },
          creator_id: {
            type: 'select',
            key: 'creator_id',
            value: 1,
            label: this.$t('models.kudo.creator'),
            rules: [{name: 'requiredSelect'}],
            options: [],
            itemText: 'text',
            itemValue: 'key',
            classes: 'col-12 py-0',
            serverErrors: [],
            disabled: false
          },
          assignee_id: {
            type: 'select',
            key: 'assignee_id',
            value: '',
            placeholder: this.$t('models.kudo.assignee'),
            label: this.$t('models.kudo.assignee'),
            rules: [{name: 'requiredSelect'}],
            options: [],
            itemText: 'text',
            itemValue: 'key',
            classes: 'col-12 py-0',
            serverErrors: []
          }
        }
      },
      title: {
        text: this.$t('layout.mainMenu.kudos'),
        icon: 'mdi-trophy-award'
      },
      breadcrumbs: [
        {
          text: this.$t('layout.mainMenu.home'),
          to: {name: 'Home'}
        },
        {
          text: this.$t('layout.mainMenu.kudos'),
          to: {name: 'Kudos'},
          exact: true
        }
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['title'],
        sortDesc: [false],
        mustSort: ['true'],
        multiSort: false
      },
      actionButton: {
        text: this.$t('actions.give', {entity: this.$tc('models.kudo.entity', 2)}),
        icon: 'mdi-plus'
      },
      headers: [
        {
          text: '',
          value: 'id',
          align: ' d-none'
        },
        {
          text: this.$t('models.kudo.attributes.title'),
          value: 'title'
        },
        {
          text: this.$t('models.kudo.attributes.description'),
          value: 'description'
        },
        {
          text: this.$t('models.kudo.attributes.badge'),
          value: 'badge'
        },
        {
          text: this.$t('models.kudo.attributes.creator_name'),
          value: 'creator_name'
        },
        {
          text: this.$t('models.kudo.attributes.assignee_name'),
          value: 'assignee_name'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '120px'
        }
      ]
    };
  },
  computed: mapGetters(['kudos', 'users', 'user', 'isAdmin', 'currentUser']),
  methods: {
    ...mapActions([
      'getKudos',
      'createKudo',
      'getUsers',
      'destroyKudo'
    ]),
    updateOptions(options) {
      this.loading = true;
      this.getKudos(options).then(() => (this.loading = false));
    },
    openCreateKudosModal() {
      this.modal.resetForm = true;
      this.modal.title = this.$t('views.kudos.newKudo');
      this.modal.behaviour = 'createKudo';
      this.modal.show = true;
      this.$nextTick(() => {
        this.modal.resetForm = false;
      });
    },
    deleteKudo(kudo) {
      this.$confirm(
        this.$t('messages.confirmation.delete', {entity: kudo.name}),
        {
          buttonTrueText: this.$t('actions.confirm'),
          buttonFalseText: this.$t('actions.cancel')
        }
      ).then((confirmed) => {
        if (confirmed) {
          this.destroyKudo(kudo.id).then((status) => {
            if (this.successCode(status)) {
              this.updateOptions(this.options);
            }
          });
        }
      });
    },
    submitModal(data) {
      if (data.assignee_id === data.creator_id) {
        this.modal.fields.assignee_id.value = '';
        this.modal.fields.assignee_id.placeholder = '';
      } else {
        const request = this.createKudo(data);
        request.then((response) => {
          if (this.successCode(response.status)) {
            this.modal.show = false;
            this.updateOptions(this.options);
          } else {
            this.displayInlineServerErrors(this.modal.fields, response.errors);
          }
        });
      }
    }
  },
  created() {
    Promise.all([
      this.getUsers(),
      this.getKudos(this.options)
    ]).then(()=>{
      this.modal.fields.assignee_id.options = this.users.items
        .filter((user) => user.id !== this.currentUser.id)
        .map((user) => ({key: `${user.id}`, text: user.name}));
      if (this.isAdmin) {
        this.modal.fields.creator_id.options = this._.map(this.users.items, (user) => ({key: `${user.id}`, text: user.name}));
      } else {
        this.modal.fields.creator_id.options = [
          {
            key: this.currentUser.id,
            text: this.currentUser.name
          }
        ];
      }
      this.modal.fields.creator_id.value = this.currentUser.id;
      this.modal.fields.creator_id.placeholder = this.currentUser.name;
    });
  }
};
</script>
